/* You can add global styles to this file, and also import other style files */
/* from public/styles.css */
@charset "UTF-8";
@import "~bootstrap/dist/css/bootstrap.min.css";

@import "~@swimlane/ngx-datatable/index.css";
@import "~@swimlane/ngx-datatable/themes/material.css";
@import "~@swimlane/ngx-datatable/assets/icons.css";
@import "~@ng-select/ng-select/themes/default.theme.css";
/* @import "./fontastic.css"; */

/* from public/css/fontastic.css */

/* data-table */
@font-face {
  font-family: "data-table";
  src: url("https://file.myfontastic.com/Jnf54BZCm7mSjGCxNRbfp3/fonts/1447204703.eot");
  src: url("https://file.myfontastic.com/Jnf54BZCm7mSjGCxNRbfp3/fonts/1447204703.eot?#iefix")
      format("embedded-opentype"),
    url("https://file.myfontastic.com/Jnf54BZCm7mSjGCxNRbfp3/fonts/1447204703.woff")
      format("woff"),
    url("https://file.myfontastic.com/Jnf54BZCm7mSjGCxNRbfp3/fonts/1447204703.ttf")
      format("truetype"),
    url("https://file.myfontastic.com/Jnf54BZCm7mSjGCxNRbfp3/fonts/1447204703.svg#1447204703")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: "data-table" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "data-table" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.environment-name {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: right;
  background: none;
  font-weight: 800;
  opacity: 25%;
  font-size: 1.5rem;
  line-height: 1.5rem;
  padding: 8px 25px;
  z-index: 5000;
  pointer-events: none;
}
.icon-filter:before {
  content: "b";
}

.icon-collapse:before {
  content: "a";
}

.icon-expand:before {
  content: "c";
}

.icon-close:before {
  content: "d";
}

.icon-up:before {
  content: "e";
}

.icon-down:before {
  content: "f";
}

.icon-sort:before {
  content: "g";
}

.icon-done:before {
  content: "h";
}

.icon-done-all:before {
  content: "i";
}

.icon-search:before {
  content: "j";
}

.icon-pin:before {
  content: "k";
}

.icon-add:before {
  content: "m";
}

.icon-left:before {
  content: "o";
}

.icon-right:before {
  content: "p";
}

.icon-skip:before {
  content: "q";
}

.icon-prev:before {
  content: "r";
}

/* end from public/css/fontastic.css */

body {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

.btn {
  border-radius: 3px !important;
}

input {
  border-radius: 2px !important;
}

.alert {
  padding: 5px !important;
}

.full-height {
  height: 100%;
  overflow: scroll;
}

.shadow {
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  /*overflow: auto;*/
}

.strong-font {
  font-weight: 600;
}

.point {
  cursor: pointer;
}

.pull-right {
  float: right;
}

.shift-right {
  text-align: right;
}

.display-none {
  display: none;
}

.text-left {
  text-align: left;
}

html,
body {
  height: 100%;
}

.min-100 {
  height: 100%;
}

.page-container {
  height: 100%;
  position: relative;
  padding-top: 130px;
  padding-bottom: 35px;
}

.page-heading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 15px;
}

.lqa-blue {
  color: #058bff !important;
}

.hcho-green {
  color: #5cb85c !important;
}

/* Loader styles*/
.loading-overlay {
  z-index: 999999;
  box-shadow: 0 0 0 2500px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 0 2500px rgba(0, 0, 0, 0.5);
  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: 0 0 0 2500px rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 20%;
  min-width: 250px;
  height: 200px;
  /* Safari bug where the box shadow won't show if the radius is always the same */
  border-radius: 4px 4px 3px 3px;
  background: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.loading-overlay img {
  display: block;
  background-color: black;
  width: 100%;
  margin-bottom: 15px;
  padding: 0 20px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.loading-overlay h4 {
  text-align: center;
  margin-top: 15px;
}

.fa-spinner {
  color: #5cb85c;
}

/*ngClass styles for Work Order Status Pipe*/

.statusRed {
  background: #e60000;
  padding: 2px 4px;
  color: #fff;
  width: 60px;
  text-align: center;
  border-radius: 4px;
}

.statusGreen {
  background: #00b300;
  padding: 2px 4px;
  color: #fff;
  width: 60px;
  text-align: center;
  border-radius: 4px;
}

/*confirm Box*/
.confirmBox {
  width: 300px;
  display: inline-block;
  padding: 10px;
  position: absolute;
  margin-left: 10px;
}

.confirmBox .confirm-btns {
  text-align: right;
  margin-top: 15px;
  border-top: 1px solid #ddd;
}

.confirmBox .confirm-btn {
  padding: 0 5px;
}

dashboard .pagination ul, drivers .pagination ul {
  margin: 0 auto;
}

dashboard .pagination ul > li > a {
  padding: 5px 9px;
  border-top: none;
  border-bottom: none;

}

/*Table Classes*/
th {
  min-height: 68px;
}

.td-5 {
  width: 5%;
}

.td-10 {
  width: 10%;
}

.td-15 {
  width: 15%;
}

.td-20 {
  width: 20%;
}

.td-25 {
  width: 25%;
}

.td-30 {
  width: 30%;
}

.td-40 {
  width: 40%;
}

.td-50 {
  width: 50%;
}

.td-60 {
  width: 60%;
}

.td-70 {
  width: 70%;
}

.td-80 {
  width: 80%;
}

.td-90 {
  width: 90%;
}

.td-100 {
  width: 100%;
}

.td-text-hide {
  max-height: 200px;
  overflow-y: hidden;
  text-overflow: ellipsis;
}

/*!* Global Layout Helper Classes*!*/

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-top-50 {
  margin-top: 50px;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-left-15 {
  margin-left: 15px;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.padding-top-15 {
  padding-top: 15px;
}

.padding-top-10 {
  padding-top: 10px;
}

.padding-top-50 {
  padding-top: 50px;
}

.padding-top-20 {
  padding-top: 20px;
}

.padding-left-20 {
  padding-left: 20px;
}

.padding-15 {
  padding: 15px 15px;
}

.padding-bottom-10 {
  padding-bottom: 10px;
}

.padding-bottom-20 {
  padding-bottom: 20px;
}

.padding-right-10 {
  padding-right: 10px;
}

.padding-0-20 {
  padding: 0 20px;
}

.side-padding-15 {
  padding-left: 15px;
  padding-right: 15px;
}

/*Style for sidebar content*/
.content {
  padding: 30px 75px;
}

.text-center {
  text-align: center;
}

.header {
  padding: 20px 0;
}

.header h2,
.header h4 {
  border-bottom: 1px solid #333;
}

/* Global Panel Styling*/
.panel {
  border: none;
  border-radius: 4px;
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  margin: 5px 0;
}

.panel .panel-header {
  height: 10%;
  padding: 10px;
}

.panel .panel-content {
  height: 75%;
  padding: 10px;
  overflow-y: auto;
  overflow-x: hidden;
}

.panel .panel-footer {
  height: 15%;
  padding: 10px;
}

.prefs-icon {
  background: #666;
  position: fixed;
  bottom: 5px;
  right: 5px;
  padding: 3px 9px;
  border-radius: 50%;
  color: #fff;
  font-size: 1.2em;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

/* Flex container styling*/
.flex-container {
  display: flex;
  flex-direction: row;
}

.flex-container-col {
  display: flex;
  flex-direction: column;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-space-around {
  justify-content: space-around;
}

.flex-end {
  justify-content: flex-end;
}

.flex-center {
  justify-content: center;
}

/*Width stylings*/
.full-width {
  width: 100%;
}

main {
  margin-left: 200px;
  padding: 0 20px;
  font-weight: 400;
  -webkit-transition: 0.2s margin-left ease-in-out;
  -moz-transition: 0.2s margin-left ease-in-out;
  -o-transition: 0.2s margin-left ease-in-out;
  transition: 0.2s margin-left ease-in-out;
}

main.smallSidebar {
  margin-left: 50px;
}

main.smallSidebar .admin-sidebar {
  padding-left: 50px !important;
}

/* global font weight */
h1,
h2,
h3,
h4,
h5 {
  font-weight: 300;
}

.table thead {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.table thead th {
  font-weight: 500;
}

.btn-margin-right {
  margin-right: 15px;
}

.bg-success {
  background-color: #dff0d8;
  padding: 15px;
  border-radius: 4px;
}

.bg-danger {
  background-color: darkred;
  padding: 15px;
  border-radius: 4px;
}

.address-btn {
  width: 150px;
  padding-left: 0;
  padding-right: 0;
}

/* toast and notifications css */
.toast-container .alert {
  position: relative;
  margin: 5px auto 0 auto;
  width: 500px;
  max-width: 100%;
  padding: 8px 35px 8px 55px !important;
  color: #000;
  font-weight: 500;
  font-size: 14px;
  border-radius: 2px;
  background: #fff;
  opacity: 0.95;
}

.toast-container i {
  color: #fff;
  font-size: 20px;
  padding: 10px;
  position: absolute;
  top: 0;
  left: 0;
  height: 45px;
  width: 45px;
}

.toast-container i {
  height: 100%;
}

.toast-container .alert-danger > i {
  background: #c92626;
}

.toast-container .alert-success > i {
  background: #32cd32;
}

.toast-container .alert-warning > i {
  background: #d1d702;
}

.toast-container .close {
  color: #333;
  opacity: 0.7;
}

.toast-container .close:hover {
  opacity: 1;
}

/* modal styles */
.modal-dialog {
  margin-top: 100px;
}

.modal-header {
  background: #333;
  color: #fff;
  padding: 5px 15px;
  border-radius: 2px 2px 0 0;
  margin: 0;
}

.modal-header h4 {
  font-size: 18px;
}

.modal-header i {
  font-size: 20px;
  margin: 2px 0;
}

.modal-dialog {
  border-radius: 3px;
}

.prompt-content > modal-body > div,
.promt-content > div {
  margin-bottom: 5px;
  padding: 0 8px;
}

.modal-buttons {
  text-align: right;
  padding: 5px 15px 15px 15px;
}

.modal-buttons button {
  border-radius: 3px;
}

.modal-divider {
  margin: 10px 0;
}

/* ss multiselect styles */
.glyphicon {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.glyphicon:before {
  content: "\F096";
}

.glyphicon-ok:before {
  content: "\F046";
}

ng-select a:hover,
ng-select a:focus {
  text-decoration: none !important;
}

/* modal datepicker styles*/
.date-modal {
  position: relative;
  width: 100%;
  box-shadow: 1px 1px 4px rgba(1, 1, 1, 0.3);
  z-index: 10;
}

.date-modal .well {
  margin: 0 auto;
  background: #c0c0c0;
}

.date-modal .btn {
  padding: 4px 2px;
  width: 100%;
  font-size: 14px;
}

.date-modal .btn.btn-light.active {
  background: #61a9e7;
}

.date-modal .btn.btn-light.active span {
  color: #fff !important;
}

.date-modal table {
  width: 100%;
  table-layout: fixed;
}

.date-modal daypicker:focus,
.date-modal monthpicker:focus,
.date-modal yearpicker:focus,
.date-modal table:focus,
.date-modal thead:focus,
.date-modal tr:focus {
  outline: none;
}

.date-modal th {
  text-align: center;
}

.date-modal td {
  position: relative;
  padding: 1px;
}

.glyphicon-chevron-left,
.glyphicon-chevron-right,
.glyphicon-chevron-up,
.glyphicon-chevron-down {
  font: normal normal normal 18px/1 FontAwesome !important;
}

.glyphicon-chevron-left:before {
  content: "\f060";
}

.glyphicon-chevron-right:before {
  content: "\f061";
}

.glyphicon-chevron-up:before {
  content: "\f062";
}

.glyphicon-chevron-down:before {
  content: "\f063";
}

.time-modal table {
  width: 100%;
}

.time-modal td {
  text-align: center;
}

.time-modal input {
  text-align: center;
  display: inline-block;
}

/* select on table rows */
.selected {
  background: #d6e4ff !important;
}

.selected:hover {
  background: #b8d0ff !important;
}

.deleting {
  background: #f2ff98 !important;
}

.deleting:hover {
  background: #f2ff98 !important;
}

.overflow-auto {
  overflow: auto;
}

.route-separator {
  border-bottom: 2px solid #ccc;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

#tablet-flex-column {
  display: flex;
}

/* multi select styles */
ng-select#service-region button,
ng-select#states-select button {
  min-width: 200px;
  margin-bottom: 5px;
}

@media (min-width: 740px) {
  mobile-navbar {
    display: none;
  }
}

@media (max-width: 739px) {
  #sidebar {
    display: none;
  }

  main {
    margin-left: 0px;
  }

  #admin-topnav {
    display: none;
  }

  main.smallSidebar {
    margin-left: 0;
  }

  .mobile-display-none {
    display: none;
  }

  .notMobile {
    display: none;
  }
}

.input-group {
  width: auto;
}

@media print {
  @page {
    size: 350mm 450mm;
    margin: 10mm;
    margin-right: 10mm;
  }

  .page-break {
    page-break-after: always;
  }

  .no-print {
    display: none;
  }

  .show-on-print {
    display: block !important;
  }

  input {
    border: none !important;
  }
}

@media (max-width: 1234px) {
  .full-width #recent-table {
    min-width: 1000px;
    overflow-x: scroll;
  }

  .recent-table-body td {
    overflow: scroll;
  }

  .panel .panel-content.mobile-panel {
    overflow-x: scroll;
  }
}

@media (min-width: 769px) {
  .tablet-flex-column {
    display: block;
  }

  #tablet-flex-column {
    flex-direction: row;
  }
}

@media (max-width: 768px) {
  #tablet-flex-column,
  .tablet-flex-column {
    display: flex;
    flex-direction: column;
  }
}

li .fa-map.lightyellow {
  color: lightyellow;
}

.lightyellow {
  color: lightyellow;
}

.lightgreen {
  color: lightgreen;
}
.btn {
  margin-left: 10px;
  &:first-of-type {
    margin-left: 0;
  }
}
.margin-right-10 {
  margin-right: 10px;
}

.email-select .btn {
  white-space: normal;
}

/* end from public/styles.css */
